<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheModalWrap from "@main/packages/TheModalWrap";
    import TheFormWrapper from "@main/packages/TheFormWrapper";

    export default {
        name: "ModalPreOrder",
        components: { TheModalWrap, TheFormWrapper },
        props: {
            city: String,
            productId: Number,
        },
        data() {
            return {
                errorsList: {
                    name: "",
                    phone: "",
                    email: "",
                },
                isLoading: false,
                successText: "",
            };
        },
        methods: {
            send({ name, phone, email }) {
                this.isLoading = true;

                this.$store.productsApi
                    .sendPreOrder({
                        name,
                        phone,
                        email,
                        productId: this.productId,
                        city: this.city,
                    })
                    .then((response) => {
                        this.isLoading = false;

                        if (response.error) {
                            this.errorsList[response.code] = response.msg;
                            return false;
                        }

                        this.successText =
                            "Мы приняли заявку. Как только товар поступит в наличие, мы сразу вас оповестим!";

                        sendAnalytics.send(sendAnalytics.events.preorder);
                    });
            },
        },
    };
</script>

<template>
    <the-modal-wrap title="Сообщить о поступлении">
        <the-form-wrapper
            title="Введите данные, по которым мы сможем сообщить вам о поступлении товара"
            name-button="Ок"
            :is-loading="isLoading"
            :resulting-errors-list="errorsList"
            @submit-form="send"
            :success-text="successText"
        >
        </the-form-wrapper>
    </the-modal-wrap>
</template>
